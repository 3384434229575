.footer {
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  color: #fff;
  padding: 20px 10px;
  margin-top: 60px;
  text-align: center;
  position: relative;
  /* border-top: 4px solid #ffcc00; */
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}

.footer-link {
  color: #ffcc00;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #ffffff;
}

.footer-text {
  font-size: 0.9rem;
  margin-bottom: 15px;
}

.footer-text .highlight {
  color: #ffcc00;
  font-weight: bold;
}

.footer-email {
  color: #ffcc00;
  text-decoration: none;
}

.footer-email:hover {
  color: #ffffff;
  text-decoration: underline;
}

.footer-socials {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.social-link {
  color: #ffcc00;
  font-size: 1.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-link:hover {
  color: #ffffff;
  transform: scale(1.2);
}
