:root {

  /**
   * colors
   */

  --selective-yellow: hsl(42, 94%, 55%);
  --eerie-black-1: hsl(0, 0%, 9%);
  --eerie-black-2: hsl(180, 3%, 7%);
  --quick-silver: hsl(0, 0%, 65%);
  --radical-red: hsl(351, 83%, 61%);
  --light-gray: hsl(0, 0%, 80%);
  --isabelline: hsl(36, 33%, 94%);
  --gray-x-11: hsl(0, 0%, 73%);
  --kappel_15: hsla(170, 75%, 41%, 0.15);
  --platinum: hsl(0, 0%, 90%);
  --gray-web: hsl(0, 0%, 50%);
  --black_80: hsla(0, 0%, 0%, 0.8);
  --white_50: hsla(0, 0%, 100%, 0.5);
  --black_50: hsla(0, 0%, 0%, 0.5);
  --black_30: hsla(0, 0%, 0%, 0.3);
  --kappel: hsl(170, 75%, 41%);
  --white: hsl(0, 0%, 100%);

  /**
   * gradient color
   */

  --gradient: linear-gradient(-90deg,hsl(151, 58%, 46%) 0%,hsl(170, 75%, 41%) 100%);

  /**
   * typography
   */

  --ff-league_spartan: 'League Spartan', sans-serif;
  --ff-poppins: 'Poppins', sans-serif; 

  --fs-1: 4.2rem;
  --fs-2: 3.2rem;
  --fs-3: 2.3rem;
  --fs-4: 1.8rem;
  --fs-5: 1.5rem;
  --fs-6: 1.4rem;
  --fs-7: 1.3rem;

  --fw-500: 500;
  --fw-600: 600;

  /**
   * spacing
   */

  --section-padding: 75px;

  /**
   * shadow
   */

  --shadow-1: 0 6px 15px 0 hsla(0, 0%, 0%, 0.05);
  --shadow-2: 0 10px 30px hsla(0, 0%, 0%, 0.06);
  --shadow-3: 0 10px 50px 0 hsla(220, 53%, 22%, 0.1);

  /**
   * radius
   */

  --radius-pill: 500px;
  --radius-circle: 50%;
  --radius-3: 3px;
  --radius-5: 5px;
  --radius-10: 10px;

  /**
   * transition
   */

  --transition-1: 0.25s ease;
  --transition-2: 0.5s ease;
  --cubic-in: cubic-bezier(0.51, 0.03, 0.64, 0.28);
  --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);

}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
li { list-style: none; }
