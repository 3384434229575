.about-us-section {
  padding: 80px 15px;
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  margin-top: 50px;
}

.about-us-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
}

.about-us-title .highlight {
  color: #ffcc00;
}

.about-us-text {
  font-size: 1.2rem;
  margin-bottom: 30px;
  line-height: 1.6;
}

.about-us-features {
  list-style: none;
  padding: 0;
}

.about-us-features li {
  font-size: 1.1rem;
  margin: 10px 0;
  padding-left: 1.5rem;
  position: relative;
}

.about-us-features li::before {
  content: "✔";
  position: absolute;
  left: 0;
  color: #00ff99;
  font-weight: bold;
}

.about-us-image-wrapper {
  text-align: center;
}

.about-us-image {
  max-width: 100%;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.about-us-image:hover {
  transform: scale(1.05);
}

.about-us-buttons .btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  margin-right: 10px;
}

.btn-primary {
  background-color: #ffcc00;
  color: #333;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.btn-primary:hover {
  background-color: #fff;
  color: #ffcc00;
  transform: translateY(-2px);
}

.btn-secondary {
  background-color: transparent;
  color: #ffcc00;
  border: 2px solid #ffcc00;
}

.btn-secondary:hover {
  background-color: #ffcc00;
  color: #333;
  transform: translateY(-2px);
}
