.signup-container .signup-image{
    padding: 20vh;
}

.signup-container .signup-image{
    padding: 20vh;
}

.signup-container .signup-form{
    padding: 20vh;
}


@media (max-width: 768px) {

    .signup-container .signup-image {
       display: none;
    }
  
    .signup-container .signup-form{
        padding: 5vh;
    }
    
}