.not-found-container {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    height: 80vh;
    margin-top: 10vh;
  }
  
  .not-found-image {
    max-width: 100%;
    height: 80vh; 
    width: 100%;
  }
  
  .not-found-text {
    font-size: 24px;
    margin-top: 20px;
    text-align: center;
    color: #333;
  }
  