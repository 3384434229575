.hero-section {
  padding: 120px 15px;
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
}

.section-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
}

.section-title .highlight {
  color: #ffcc00;
}

.hero-text {
  font-size: 1.2rem;
  margin-bottom: 30px;
  line-height: 1.6;
}

.hero-buttons .btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  margin-right: 10px;
}

.btn-primary {
  background-color: #ffcc00;
  color: #333;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.btn-primary:hover {
  background-color: #fff;
  color: #ffcc00;
  transform: translateY(-2px);
}

.btn-secondary {
  background-color: transparent;
  color: #ffcc00;
  border: 2px solid #ffcc00;
}

.btn-secondary:hover {
  background-color: #ffcc00;
  color: #333;
  transform: translateY(-2px);
}

.hero-image {
  max-width: 100%;
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.hero-image:hover {
  transform: scale(1.05);
}
